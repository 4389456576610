<template>
  <div>
    <br />
    <div class="row px-2">
      <div :class="errors != [] ? 'rounded bg-white border' : ''">
        <div v-for="(error, index) in errors" :key="index++" class="col-12">
          <div class="text-danger py-1">
            {{ index + " - " + error }}
          </div>
        </div>
      </div>
    </div>
    <br />

    <div class="row justify-content-end">
      <div class="col-auto">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'sales-items-create' })"
        >
          <i class="bi bi-file-earmark-plus fs-4"></i>
          Ajouter d'autres articles
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-between">
      <div class="col-xl col-12 d-flex">
        <div class="col-auto">Total Quantite :</div>
        <div class="col">
          <span
            v-if="numberItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ numberItems }}
          </span>
          Élément
        </div>
      </div>

      <div class="col-xl col-12 d-flex">
        <div class="col-auto">Quantité totale :</div>
        <div class="col">
          <span
            v-if="totalQuantityItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalQuantityItems }}
          </span>
        </div>
      </div>

      <div class="col-xl col-12 d-flex">
        <div class="col-auto">Montant Total (TTC*) :</div>
        <div class="col">
          <span
            v-if="totalPriceTTCItems != ''"
            class="fw-bold bg-warning px-2 py-1 rounded"
          >
            {{ totalPriceTTCItems }}
          </span>
          DH
        </div>
      </div>
    </div>
    <hr />
    <br />
    <div class="row align-items-end">
      <!-- <div class="col-xl-3 col-12 my-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="sale.customer_reference"
          :disabled="sale.customer_reference ? true : false"
          class="form-select"
        >
          <option value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div> -->

      <div class="col-xl-3 col-12 my-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <input
          type="text"
          list="customerList"
          v-model="customer_reference"
          class="form-select"
          @input="onCustomerChangeSelected(customer_reference)"
        />
        <datalist id="customerList">
          <option value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </datalist>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> CLient <span class="text-danger">*</span></label>
        <input
          :value="selectedCustomer.fullName"
          :disabled="true"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Total </label>
        <input
          :value="totalPriceTTC"
          :disabled="true"
          class="form-control text-primary border-primary"
        />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Total Payé </label>
        <input
          :value="totalPaid"
          :disabled="true"
          class="form-control text-success border-success"
        />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Total Impayé </label>
        <input
          :value="totalUnpaid"
          :disabled="true"
          class="form-control text-danger border-danger"
        />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Statut </label>
        <input
          :value="
            selectedCustomer.maxUnpaid != 0 &&
            totalUnpaid <= selectedCustomer.maxUnpaid
              ? 'Non autorisé'
              : 'Autorisé'
          "
          :class="
            selectedCustomer.maxUnpaid != 0 &&
            totalUnpaid <= selectedCustomer.maxUnpaid
              ? 'text-danger border-danger'
              : 'text-success border-success'
          "
          :disabled="true"
          class="form-control"
        />
      </div>


      <div class="col-xl-3 col-12 my-3">
        <label for="">Date <span class="text-danger">*</span></label>
        <input type="date"  :disabled="$userRole!='Admin'?true:false" v-model="sale.date" class="form-control" />
      </div>

      <div class="col-auto my-3">
        <label for=""> </label>
        <button @click="selectTodayDate" class="btn btn-secondary">
          Date d'aujourd'hui
        </button>
      </div>

      <div v-if="userRole == 'Admin'" class="col-xl-3 col-12 my-3">
        <label for="">Numéro du conteneur </label>
        <input
          type="text"
          v-model="sale.containerNumber"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Date de livraison </label>
        <input
          type="datetime-local"
          v-model="sale.deliveryDateTime"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Livré par </label>
        <select v-model="sale.delivery_reference" class="form-select">
          <option value="--">--</option>

          <option
            v-for="(employee, index) in employees.filter(
              (p) => p.fonction == 'delivery'
            )"
            :key="index++"
            :value="employee.reference"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Échéance </label>
        <input type="date" v-model="sale.deadline" class="form-control" />
      </div>

      <div class="col-xl-12 col-12 my-3">
        <label for=""> Remarque <span class="text-danger">*</span></label>
        <textarea
          type="date"
          v-model="sale.remark"
          class="form-control"
        ></textarea>
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à la vente (Client,
            Quantité totale, Mantant totale, Nombre d'articles, ...) et
            correctes. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-12">
        <button
          class="btn btn-success"
          @click="save(sale)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-xl-12 col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sale: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        amountPaid: 0.0,
        customer_reference: this.$route.query.customer_reference,
      },
      accept: false,
      selectedCustomer: {},
      customer_reference: "",
    };
  },
  computed: {
    ...mapGetters("sale", {
      sale_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),

    ...mapGetters("customer", {
      customers: "getAll",
    }),

    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),

    ...mapGetters("sale", {
      sales: "getSales",
      totalPriceTTC: "getSalesTotalPriceTTC",
      totalPaid: "getSalesTotalPaid",
      totalUnpaid: "getSalesTotalUnpaid",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("sale/store", data);
      await setTimeout(((this.sale = {}), (this.accept = false)), 2000);
    },
    async refresh() {
      this.$store.dispatch("sale/getAllItems");
      this.$store.dispatch("employee/getAll");
    },

    async onCustomerChangeSelected() {
      await (this.selectedCustomer = this.customers.find(
        (p) => p.reference == this.customer_reference
      ));

      await (this.sale.customer_reference = this.selectedCustomer.reference);

      if (this.selectedCustomer != null) {
        await this.getCustomerSalesUnpaid(this.customer_reference);
        await (this.customer_reference = "");
      }
    },

    async getCustomerSalesUnpaid(customer_reference) {
      this.$store.dispatch("sale/getCustomerSalesUnpaid", customer_reference);
    },
    async selectTodayDate(){
      this.sale.date= new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0];
    }
  },
  async beforeMount() {
    await this.$store.dispatch("sale/getAllItems");
    await this.$store.dispatch("customer/getAll");
    this.$store.dispatch("employee/getAll");
    (await !this.numberItems)
      ? (await this.$store.dispatch("notification/store", {
          status: "error",
          message: "Aucun élément n'est ajouté. Veuillez en ajouter.",
        }),
        await this.$router.push({ name: "sales-items-create" }))
      : "";
  },
};
</script>
